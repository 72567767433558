<script setup lang="ts">
const props = defineProps({
  showPrev: {
    type: Boolean,
    default: false,
  },
  showNext: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: ""
  }
});
const emits = defineEmits(["prevSlide", "nextSlide"]);
const prevSlide = () => {
  emits("prevSlide");
};
const nextSlide = () => {
  emits("nextSlide");
};
</script>
<template>
  <button @click="prevSlide" v-if="showPrev" :title="$t('prev')" :class="{
    'w-9 h-9 md:w-12 md:h-12 -left-3': !size,
    'w-9 h-9 left-2': size == 'small'
  }"
    class=" absolute  z-[1] top-1/2 -translate-y-1/2 text-lg text-neutral-500 dark:text-neutral-200 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-50 dark:hover:border-neutral-500 rounded-full inline-flex items-center justify-center hover:border-neutral-300 focus:outline-none">
    <Icon name="solar:alt-arrow-left-outline" size="24" />
  </button>
  <button @click="nextSlide" v-if="showNext" :title="$t('next')" :class="{
    'w-9 h-9 md:w-12 md:h-12 right-3': !size,
    'w-9 h-9 right-2': size == 'small'
  }"
    class=" absolute - z-[1] top-1/2 -translate-y-1/2 text-lg text-neutral-500 dark:text-neutral-200 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-50 dark:hover:border-neutral-500 rounded-full inline-flex items-center justify-center hover:border-neutral-300 focus:outline-none">
    <Icon name="solar:alt-arrow-right-outline" size="24" />
  </button>
</template>
